import { Link } from "gatsby";
import React from "react";
import { SectionWrapper, H1, P } from "../SectionStyled";
import { PriceWrapper } from "./KonditionenStyled";

const Konditionen: React.FC = () => (
  <>
    <SectionWrapper>
      <H1>Preise & Konditionen</H1>
      <P>
        Meine wissenschaftlich fundierten Methoden sind darauf ausgerichtet, so
        effizient wie möglich belastbare Ergebnisse zu erzielen. In vielen
        Fällen sind die gesetzten Ziele nach einer begrenzten Anzahl an
        Sitzungen erreicht. Gerne sprechen wir vor Beginn unserer Zusammenarbeit
        über den erwartbaren Zeit- und Kostenaufwand. Bitte entscheiden Sie sich
        ganz in Ruhe. Meine aktuellen Preise sind:
      </P>
      <PriceWrapper>
        <P>
          Erstgespräch (mind. 50 Minuten): 60,- EUR <br />
          Sitzung 50 Minuten: 85,- EUR
          <br />
          Sitzung 80 Minuten: 105,- EUR
          <span>
            <br />
            -ggf. zzgl. 19% MwSt.
            <br />
            -Psychotherapeutische Leistungen sind gem. §4, Nr.14a UStG von der
            MwSt. befreit <br />
            Detailierte Informationen zu den Konditionen, Durchführung und
            Zahlung
            <Link to="/konditionen"> {">"} Mehr lesen</Link>
          </span>
        </P>
      </PriceWrapper>
    </SectionWrapper>
  </>
);

export default Konditionen;
